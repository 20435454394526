import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from './assets/softshell.png'; // Adjust the path to your logo image

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light pl-10" style={{ paddingLeft: '5%', paddingRight: '5%' }}>
      <a className="navbar-brand" href="/">
        <img src={logo} alt="Your Brand Name" style={{ height: '40px' }} /> {/* Adjust the height as needed */}
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
        <ul className="navbar-nav">
          <li className="nav-item">
            <NavLink exact to="/" className="nav-link" activeClassName="active">
              Home
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="./contact" className="nav-link" activeClassName="active">
              Contact
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
