import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

const PrivacyPolicy = () => {
  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card>
            <Card.Body>
              <h1>Privacy Policy</h1>
              <p>
                This Privacy Policy describes how we collect and use your information when you contact us through our application.
              </p>

              <h2>Information We Collect</h2>
              <p>
                We only collect the information you provide directly to us when you contact us. This includes your name, email address, and phone number.
              </p>

              <h2>How We Use Information</h2>
              <p>
                We use the information we collect solely to contact you back regarding your inquiries or requests. We do not use your information for any other purposes.
              </p>

              <h2>Information Sharing</h2>
              <p>
                We do not share your personal information with any third parties, except as required by law or to respond to legal processes.
              </p>

              <h2>Your Choices</h2>
              <p>
                You can contact us at any time to request the update or deletion of your information.
              </p>

              <h2>Contact Us</h2>
              <p>
                If you have any questions about this Privacy Policy, please contact us at [your contact information].
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;