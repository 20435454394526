import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import emailjs from '@emailjs/browser';
import { useParams } from 'react-router-dom';

const Contact = () => {
  const [formData, setFormData] = useState({
    from_name: '',
    email: '',
    ph_no: '',
    message: '',
  });
  const [submitting, setSubmitting] = useState(false);
  const { productName } = useParams();
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      formData["productName"] = productName;
      const result = await emailjs.send(
        'service_2zzn3s5', // Replace with your EmailJS service ID
        'template_7u6va4f', // Replace with your EmailJS template ID
        formData,
        'h5-Slw3GWhGPG2ODQ' // Replace with your EmailJS user ID
      );
      console.log(result.text);
      alert('Thank you! We’ll get back to you very soon.');
      setFormData({ from_name: '', email: '', ph_no: '', message: '' });
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to send email. Please try again later.');
    }
    setSubmitting(false);
  };

  return (
    <div className="container my-5">
      <h2>{productName ? "Enquiry" : "Contact"}</h2>
      {productName && <Alert variant="info">
      <Alert.Heading>{productName}</Alert.Heading>
      <p>
      You are reaching out to us regarding the selected product. Our team will review your request and get in touch with you shortly.
      </p>
      <hr />
      <p className="mb-0">
      We appreciate your interest and look forward to assisting you with your needs.
      </p>
    </Alert>}
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            name="from_name"
            value={formData.from_name}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formEmail">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formPhone">
          <Form.Label>Phone</Form.Label>
          <Form.Control
            type="tel"
            name="ph_no"
            value={formData.ph_no}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group controlId="formMessage">
          <Form.Label>Message</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="mt-3" disabled={submitting}>
          {submitting ? 'Submitting...' : 'Submit'}
        </Button>
      </Form>
    </div>
  );
};

export default Contact;
