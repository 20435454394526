import React from 'react';
import { Card, Container, Row, Col,Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import products from './data/printer.json';
import { NavLink } from 'react-router-dom';


const ProductDetail = () => {
  const { productId } = useParams();

  const product = products.find((product) => product.id.toString() === productId);

  if (!product) {
    return ( <div className="my-5" style={{ textAlign: 'center', color: 'red' }}>
        <h2>Product not found</h2>
      </div>)
  }

  return (
    <Container className='my-3'>
      <Row className="justify-content-center">
        <Col xs={12} md={8}>
          <Card>
            <Card.Img variant="top" src={product.image} alt={product.title} />
            <Card.Body>
              <Card.Title>{product.title}</Card.Title>
              <Card.Text>
                <strong>Brand:</strong> {product.brand}<br />
                <strong>Type:</strong> {product.type}<br />
                <strong>Color:</strong> {product.color}<br />
                <strong>Resolution:</strong> {product.resolution}<br />
                <strong>Price:</strong> {product.price}<br />
              </Card.Text>
              {/* <NavLink to="/cantact"> */}
              <NavLink to={`/contact/${product.title}`}>
                 <Button variant="primary">Rent at {product.price} /month</Button>
              </NavLink>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductDetail;
