import React from 'react';
import Navbar from './Navbar';
import Home from './Home';
// import LaptopList from './LaptopList';
// import PrinterList from './laptopProduct';
import ProductDetail from './ProductDetail';
import ProductPrinter from './ProductPrinter';
import Cantact from './Cantact';
import Footer from './Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css'; // Ensure this imports your global CSS
import PrivacyPolicy from './PrivacyPolicy';


function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/productDetail/:productId" element={<ProductDetail />} />
          <Route path="/productPrinter/:productId" element={<ProductPrinter />} />
          <Route path="/contact/" element={<Cantact />} />
          <Route path="/contact/:productName" element={<Cantact />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          {/* <Route path="/printers" element={<PrinterList />} /> */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
