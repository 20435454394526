import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Carousel from 'react-bootstrap/Carousel';
import baner1 from './assets/banner1.webp';
import baner2 from './assets/banner2.webp';
import { Card, Image } from 'react-bootstrap';
import data from './data/laptop.json'; // Assuming data.json is in the same directory
import "./App.css";
import data1 from './data/printer.json'; // Assuming data.json is in the same directory
import { Link } from 'react-router-dom';

const Home = () => {
  const [products, setProducts] = useState([]);
  const [products1, setProducts1] = useState([]);

  useEffect(() => {
    setProducts(data);
    setProducts1(data1);
  }, []);

  return (
    <div className="container my-4">
      <Carousel>
        <Carousel.Item>
          <Image src={baner1} fluid />
          {/* <img
            className="d-block w-100 img-fluid"
            style={{ height: '50vh' }}
            src={baner1}
            alt="Laptop"
          /> */}
          <Carousel.Caption style={{ color: 'black' }}>
            {/* <h3>Printer With Scanner</h3> */}
            {/* <p>Description of the laptop</p> */}
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
        <Image src={baner2} fluid />
          {/* <img
            className="d-block w-100"
            style={{ height: '50vh' }}
            src={baner2}
            alt="Printer"
          /> */}
          <Carousel.Caption style={{ color: 'black' }}>
            {/* <h3>Printer With Scanner</h3> */}
            {/* <p>Description of the laptop</p> */}
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      <div className="container mt-5">
      <div class="text-left mt-5">
        <h2 class="mb-3">Laptops</h2>
    </div>
        <div className="row row-cols-1 row-cols-md-4 g-4"> {/* 4 images per row on medium screens */}
          {products.map(product => (
            <div className="col" key={product.id}>
              <Link to={`/ProductDetail/${product.id}`} style={{textDecoration: 'none'}}>
              <Card hoverable>
                <Card.Img variant="top" src={product.image} alt={product.title} className="fixed-size-img"/>
                <Card.Body>
                  <Card.Title>{product.title}</Card.Title>
                  <Card.Subtitle>{product.processor}</Card.Subtitle>
                  <Card.Text>₹{product.price} /month</Card.Text>
                  {/* Add more card content if needed */}
                </Card.Body>
              </Card>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <div className="container mt-5">
      <div class="text-left mt-5">
        <h2 class="mb-3">Printer with Scanner</h2>
    </div>
      <div className="row row-cols-1 row-cols-md-4 g-4"> {/* 4 images per row on medium screens */}
        {products1.map(product => (
          <div className="col" key={product.id}>
            <Link to={`/ProductPrinter/${product.id}`} style={{textDecoration: 'none'}}>
              <Card>
                <Card.Img variant="top" src={product.image} alt={product.title} className="fixed-size-img"/>
                <Card.Body>
                  <Card.Title>{product.title}</Card.Title>
                  <Card.Subtitle>{product.type}</Card.Subtitle>
                  <Card.Text>₹{product.price} /month</Card.Text>
                  {/* <NavLink to={`/ProductPrinter/${product.id}`}>
                  <Button variant="primary">View</Button>
                  </NavLink> */}
                  {/* Add more card content if needed */}
                </Card.Body>
              </Card>
            </Link>
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default Home;
