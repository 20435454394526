import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Footer = () => {
  return (
    <footer className="bg-light text-center py-4">
      <div className="container">
        <p>&copy; {new Date().getFullYear()} Soft Shell. All rights reserved.</p>
        <ul className="list-inline">
          <li className="list-inline-item"><a href="/privacyPolicy" className="text-dark">Privacy Policy</a></li>
          {/* <li className="list-inline-item"><a href="/termsOfService" className="text-dark">Terms of Service</a></li> */}
          <li className="list-inline-item"><a href="/contact" className="text-dark">Contact Us</a></li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
